<template>
    <div class="creationCompetition">
        <el-tabs v-model="activeName" type="card" class="education-content tabs-full">
            <el-tab-pane label="竞赛管理" name="raceAdmin">
                <div class="creationCompetitionProgress">
                    <div class="competitionProgress">
                        <div class="step-num">
                            <span class="active">1</span>
                            <span>创建竞赛</span>
                        </div>
                        <img class="step-wire" :src="active > 1 ? require('./../../assets/images/serviceData/blue.png') : require('./../../assets/images/serviceData/xian.png')" alt="">
                        <div class="step-num">
                            <span :class="active > 1 ? 'active' : ''">2</span>
                            <span>选择竞赛内容</span>
                        </div>
                        <img class="step-wire" :src="active > 2 ? require('./../../assets/images/serviceData/blue.png') : require('./../../assets/images/serviceData/xian.png')" alt="">
                        <div class="step-num">
                            <span :class="active > 2 ? 'active' : ''">3</span>
                            <span>选择竞赛评委</span>
                        </div>
                        <img class="step-wire" :src="active === 4 ? require('./../../assets/images/serviceData/blue.png') : require('./../../assets/images/serviceData/xian.png')" alt="">
                        <div class="step-num">
                            <span :class="active === 4 ? 'active' : ''">4</span>
                            <span>创建成功</span>
                        </div>
                    </div>
                </div>
                <div class="creationCompetitionForm" style="min-height: calc(100vh - 330px);">
                    <keep-alive>
                        <router-view></router-view>
                    </keep-alive>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        name: "creationCompetition",
        data(){
            return {
                activeName:'raceAdmin',
                active:1,
            }
        },
        watch:{
            $route(route){
                if(route.name == 'creationCompetitionTwo'){
                    this.active = 2;
                } else if(route.name == 'creationCompetitionThree'){
                    this.active = 3
                } else if(route.name == 'creationCompetitionSucceed'){
                    this.active = 4
                }
            }
        },
        created() {
            if(this.$route.name == 'creationCompetitionTwo'){
                this.active = 2;
            } else if(this.$route.name == 'creationCompetitionThree'){
                this.active = 3
            } else if(this.$route.name == 'creationCompetitionSucceed'){
                this.active = 4
            }
        },
    }
</script>

<style scoped lang="scss">
    .creationCompetition{
        /*height: calc(100vh - 430px);*/
        height: 100%;
        box-sizing: border-box;
        /*padding: 30px;*/
        display: flex;
        flex-direction: column;
        /*flex-direction: column;*/
        .creationCompetitionProgress{
            /*padding: 60px 228px 100px 228px;*/
            /*display: flex;*/
            /*align-items: center;*/
            /*margin: 64px auto;*/
            display: flex;
            .competitionProgress{
                margin: 64px auto;
                display: flex;
                align-items: center;
                .step-num{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span:nth-child(1){
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        color: #FFFFFF;
                        background: #DCDCDC;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 48px;
                    }
                    .active{
                        background: #1122D8!important;
                    }
                    span:nth-child(2){
                        display: inline-block;
                        width: 100px;
                        text-align: center;
                        top: 68px;
                        /*left: 30%;*/
                        position: absolute;
                        font-size: 16px;
                        color: #333333;

                    }
                }
                .step-wire{
                    height: 14px;
                    margin: 0 10px;
                }
            }

        }
    }
</style>